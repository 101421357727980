import { message, Spin } from "antd";
import { isUserError } from "client/services/apiFunctions";
import { SessionStorageKeys } from "common/enums";
import { FeatureFlagsType } from "common/types";
import { observer } from "mobx-react";
import queryString from "query-string";
import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { COOKIE_NAME } from "../common/config";
import { CleaningLimitPoliciesState } from "./Data/CleaningLimitPoliciesStore";
import { DefaultFormats } from "./Data/DateTimeFormatStore";
import { defaultUnitState } from "./Data/DeafultUnits/DefaultUnitsStore";
import UserStore from "./Data/UserStore";
import { Auth } from "./scenes/Auth";
import { getFeatureFlags, getUserErrorMessage, logoutUser } from "./services/api";

type State = {
  homeComponent: JSX.Element | null;
  loading: boolean;
  featureFlags?: FeatureFlagsType;
};

const LoadingWrapper = styled.div.attrs({
  id: "auth-loading-wrapper"
})`
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
`;

const Authenticator = withRouter(
  observer(
    class AuthenticatorComponent extends React.Component<any, State> {
      constructor(props) {
        super(props);
        try {
          if (!sessionStorage.length) {
            // Uses beacon api to invalidate the old-session if any
            // logoutUser(true)
            //   .then(r => console.info(r))
            //   .catch(err => {
            //     console.error(err);
            //     throw err;
            //   });
            // Delete any old session cookie
            this.deleteSessionCookie();
          }
          const currentFacility = sessionStorage.getItem(SessionStorageKeys.CURRENT_FACILITY);
          UserStore.isUserLoggedIn = !!currentFacility;
        } catch (e) {
          console.error(e);
          sessionStorage.removeItem(SessionStorageKeys.EMAIL);
        }

        this.state = {
          homeComponent: null,
          loading: false
        };

        if (UserStore.isUserLoggedIn) {
          this.handleLogin();
        }
      }

      componentDidMount() {
        this.setState(
          {
            loading: true
          },
          async () => {
            const { history } = this.props;
            try {
              const featureFlags = await getFeatureFlags();
              const ssoEnabled = featureFlags.SSO;

              if (ssoEnabled) {
                const { error } = queryString.parse(window.location.search);

                if (error) {
                  const errorObj = JSON.parse(error as string);
                  const errorMessage = isUserError(errorObj)
                    ? getUserErrorMessage(errorObj)
                    : errorObj.message;

                  message.config({ maxCount: 1 });
                  message.warning(errorMessage);
                  history.push(window.location.pathname);
                }
              }

              this.setState({
                featureFlags
              });
            } catch (e) {
              console.error(e);
            } finally {
              this.setState({
                loading: false
              });
            }
          }
        );
      }

      handleLogin = async () => {
        try {
          await DefaultFormats.setDefaultFormats();
        } catch (err) {
          console.log(err);
        }
        try {
          await defaultUnitState.setAllDefaultUnits();
        } catch (err) {
          console.log(err);
        }
        try {
          await UserStore.setCurrentUser();
        } catch (err) {
          console.log(err);
        }
        CleaningLimitPoliciesState.fetchCleaningLimitPolicies();

        await import("./scenes/Home").then(Component => {
          this.setState(prevState => {
            const { featureFlags } = prevState;
            return {
              homeComponent: (
                <Component.default
                  handleLogout={() => this.handleLogout()}
                  featureFlags={featureFlags!}
                />
              )
            };
          });
        });
        UserStore.isUserLoggedIn = true;
      };

      handleLogout = async (force = false) => {
        try {
          const isSSOEnabled = this.state.featureFlags?.SSO;
          const logoutUrl = this.state.featureFlags?.logoutUrl;
          if (!force) {
            await logoutUser(false, isSSOEnabled, logoutUrl);
          }

          UserStore.isUserLoggedIn = false;
          sessionStorage.clear();
          this.setState({
            homeComponent: null
          });
          this.deleteSessionCookie();
        } catch (err) {
          console.error(err);
        }
      };

      deleteSessionCookie = () => {
        document.cookie = COOKIE_NAME + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      };

      render() {
        const { homeComponent, loading } = this.state;
        const auth = <Auth handleLogin={this.handleLogin} />;

        if (loading) {
          return (
            <LoadingWrapper>
              <Spin />
            </LoadingWrapper>
          );
        }

        return UserStore.isUserLoggedIn ? homeComponent : auth;
      }
    }
  )
);

export default Authenticator;
